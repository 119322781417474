const LoadingIndicator = () => {
    return (
        <div className="loading-container">
            <div className='flex flex-col items-center justify-center'>
                <p className='text-gotham-gray text-md font-bold mb-2'>Loading Item Details...</p>
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
};

export default LoadingIndicator;