import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LayerData } from "../../services/types";
import { capitalizeWords } from "../../utils/helpers";
import LoadingIndicator from "../../components/General/LoadingIndicator";

const MapItemLandingPage = () => {
    const [layerData, setLayerData] = useState<LayerData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    
    // Set layerId, point id, and partnerId from search params    
    const layerId = searchParams.get('layerId');
    const pointId = searchParams.get('id');
    const partnerId = searchParams.get('partnerId');

    // Initialize layer details and tags
    const details = layerData?.details;
    const tags = details?.[0]?.layer_tags ?? [];

    // Define current point layer item based on search params
    const items = layerData?.items;
    const item = items?.find(item => Number(item.id) === Number(pointId));

    // Get interactionId from current item
    const interactionId = item?.external_id;

    // Retrieve layer item attributes
    const attributesMap = Object.fromEntries(
        item?.attributes?.map(attr => [attr.name, attr.value]) || []
    );

    // Extract business attributes
    const address = attributesMap['address'] || '';
    const legalName = attributesMap['business_legal_name'] || '';
    const ubi = attributesMap['business_ubi'] || '';

    // Find other items with the same UBI, excluding the current item
    const relatedItems = items?.filter(
        i => i.attributes?.find(attr => attr.name === 'business_ubi')?.value === ubi && i.id !== item?.id
    ) || [];

    // Extract business interaction attributes
    const busClass = attributesMap['Classification'] || '';
    const industry = attributesMap['Industry Category'] || '';

    // Show "Add Interaction / Edit Interaction" buttons based on current tag or layer name
    const layerName = details?.[0]?.layer_name;
    const businessLayer = tags.some(tag => tag.name === 'ado_business_discovery') || layerName === 'Washington Businesses';
    const interactionLayer = tags.some(tag => tag.name === 'ado_business_interactions') || layerName === 'Business Interactions';

    // Set Survey Link
    const surveyLayer = tags.some(tag => tag.name === 'ado_business_surveys') || layerName === 'Business Surveys';
    const surveyLink = `${import.meta.env.SURVEY_LINK}/data/${item?.external_id}`;

    // Map for Category label
    const categoryLabel = businessLayer 
    ? 'Industry Category:' 
    : interactionLayer 
        ? 'Interaction Type' 
        : 'Category';

        
    // Format Activity Date
    const date = item?.activity_date ? new Date(item.activity_date) : null;

    const formattedDate = date
        ? `${String(date.getUTCMonth() + 1).padStart(2, '0')}/${String(date.getUTCDate()).padStart(2, '0')}/${date.getUTCFullYear()}`
        : '';


    // Fetch layer details, items, and summary when layerId changes
    useEffect(() => {
        const fetchLayerData = async () => {
            if (!layerId) return;
            setLoading(true);

            try {
                const response = await fetch(
                `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getlayeritems?layerId=${layerId}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
                );
                if (!response.ok) throw new Error('Failed to fetch layer items');

                const data = await response.json();
                setLayerData(data);
            } catch (error) {
                console.error(`Error fetching layer data for layer ${layerId}:`, error);
                setLayerData(null);
            } finally {
                setLoading(false);
            }
        };
  
        fetchLayerData();
    }, [layerId]);
  

    // Handler for navigating to add interaction page
    const handleAdd = () => {        
        const businessName = item?.name || '';
        const lat = item?.latitude || '';
        const long = item?.longitude || '';

        if (partnerId) {
            console.log('Adding new interaction, and prefilling the following fields:', 'business name:', businessName, '-', 'business legal name:', legalName, '-', 'address:', address, '-', 'latitude:', lat, '-', 'longitude:', long, '-', 'ubi:', ubi);

            navigate(`/add-interaction/?partnerId=${partnerId}&businessName=${businessName}&legalName=${legalName}&ubi=${ubi}&address=${address}&latitude=${lat}&longitude=${long}`);
        }
    };

    // Handler for navigating to edit interaction page
    const handleEdit = () => {
        if (interactionId && partnerId) {
            navigate(`/edit-interaction/${interactionId}?partnerId=${partnerId}`);
        }
    };


    // Show loading animation if the layers are currently loading
    if (loading) return <LoadingIndicator/>
  
    return (
        <div className="p-6">
            {/* Default view for layer with no items */}
            {(!items || items.length === 0) && details && (
                <div className="p-6 text-center">
                    <h2 className="text-lg font-semibold text-gray-800">
                        {details[0]?.layer_name || 'No Layer Name'}
                    </h2>
                    <p className="text-sm text-gray-600 mt-1">
                        {details[0]?.layer_description || 'No description available.'}
                    </p>
                    
                </div>
            )}

            {/* Header detailing layer name */}
            {/* {details &&
                <div className="p-6 text-center">
                    <h2 className="text-lg font-semibold text-gray-800">
                        {details[0]?.layer_description || 'No Layer Name'}
                    </h2>
                </div>
            } */}

            {/* Add Interaction Button (For business discovery) */}
            {businessLayer &&
                <div className="flex flex-col items-start">
                    {(!items || items.length === 0) &&
                        <p className="text-sm text-gray-500 mb-2">
                            No businesses found.
                        </p>
                    }

                    <p className="text-xs text-gotham-gray font-bold mb-2">
                        Add a business interaction using the button below:
                    </p>
                    
                    <div className="flex gap-4 items-center justify-center mb-6">
                        <button className="w-48 h-9 text-center text-white text-sm bg-[#0B5F75] border border-solid border-[#0B5F75] rounded-md focus:outline-none focus:border-blue-500  hover:bg-blue-400 hover:border-blue-500" onClick={handleAdd}>+ Add Interaction</button>
                    </div>
                </div>
            }

            {/* Selected Item View */}
            {item && (
                <div className="mb-6 bg-white rounded-md shadow-lg border border-gray-100 overflow-hidden">
                    {/* Header (Name) */}
                    <div className="p-6 bg-[#4d4d4d] rounded-t-xl flex justify-between items-center">
                        <div className="flex flex-col">
                        <p className="text-xl font-bold text-white capitalize">
                            {capitalizeWords(item?.name || '')}
                        </p>
                        </div>
                        {interactionLayer &&
                            <button
                                className="px-4 py-2 text-sm text-gray-700 w-24 bg-white border rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:opacity-50"
                                onClick={handleEdit}
                                disabled={!interactionId}
                            >
                                Edit
                            </button>
                        }
                    </div>
        
                    {/* Content */}
                    <div className="p-6 space-y-2 text-gray-700">
                        {/* Washington Business Discovery Specific */}
                        {/* Business Legal Name */}
                        {legalName && (
                            <div className="flex items-center space-x-2">
                                <span className="font-semibold text-sm">Business Legal Name:</span>
                                <span className="text-sm">
                                    {legalName}
                                </span>
                            </div>
                        )}

                        {/* Address */}
                        {address && (
                            <div className="flex items-center space-x-2">
                                <span className="font-semibold text-sm">Address:</span>
                                <span className="text-sm">
                                    {address}
                                </span>
                            </div>
                        )}

                        {/* UBI */}
                        {ubi && (
                            <div className="flex items-center space-x-2">
                                <span className="font-semibold text-sm">UBI:</span>
                                <span className="text-sm">
                                    {ubi}
                                </span>
                            </div>
                        )}

                        {/* General Layer Item Info */}
                        {/* Activity Date (hide for business discovery)*/}
                        {!businessLayer &&
                            <div className="flex items-center space-x-2">
                                <span className="font-semibold text-sm">Activity Date:</span>
                                <span className="text-sm">{formattedDate}</span>
                            </div>
                        }

                        {/* Description */}
                        {item?.description &&
                            <div className="flex items-center space-x-2">
                                <p className="font-semibold text-sm">Description:</p>
                                <p className="text-sm">
                                    {item.description}
                                </p>
                            </div>
                        }

                        {/* Category */}
                        <div className="flex items-center space-x-2">
                            <span className="font-semibold text-sm">{categoryLabel}</span>
                            <span className="text-sm">{item?.category || 'N/A'}</span>
                        </div>

                        
                        {/* Business Interaction Specific */}
                        {/* Business Classification */}
                        {busClass &&
                            <div className="flex items-center space-x-2">
                                <span className="font-semibold text-sm">Business Classification:</span>
                                <span className="text-sm">{busClass}</span>
                            </div>
                        }

                        {/* Industry Category */}
                        {industry &&
                            <div className="flex items-center space-x-2">
                                <span className="font-semibold text-sm">Industry Category:</span>
                                <span className="text-sm">{industry}</span>
                            </div>
                        }


                        {/* Survey Specific */}
                        {surveyLayer &&
                            <div className="flex items-center space-x-2">
                                <span className="font-semibold text-sm">View Survey Data Here:</span>
                                <span className="text-sm">{surveyLink}</span>
                            </div>
                        }
                    </div>
                </div>
            )}

            {/* Related Business Items by UBI */}
            {relatedItems.length > 0 && (
                <div className="mt-2">
                    <div className="space-y-2">
                        {relatedItems.map((relatedItem) => {
                            const relatedAttributesMap = Object.fromEntries(
                                relatedItem.attributes?.map(attr => [attr.name, attr.value]) || []
                            );

                            const date = relatedItem?.activity_date ? new Date(relatedItem.activity_date) : null;

                            const formattedDate = date
                                ? `${String(date.getUTCMonth() + 1).padStart(2, '0')}/${String(date.getUTCDate()).padStart(2, '0')}/${date.getUTCFullYear()}`
                                : '';

                            return (
                                <div className="mb-6 bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden">
                                    {/* Header (Name) */}
                                    <div className="p-6 bg-[#4d4d4d] rounded-t-xl flex justify-between items-center">
                                        <div className="flex flex-col">
                                        <p className="text-xl font-bold text-white capitalize">
                                            {capitalizeWords(relatedItem?.name || '')}
                                        </p>
                                        {/* Description */}
                                        {relatedItem?.description && (
                                            <div className="flex items-center space-x-2">
                                                <p className="mt-2 text-xs font-semibold text-white">Description:</p>
                                                <p className="mt-2 text-xs italic text-white">
                                                    {relatedItem?.description || ''}
                                                </p>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                    <div className="p-6 space-y-4 text-gray-700">
                                        {/* Washington Business Discovery Specific */}
                                        {/* Business Legal Name */}
                                        {relatedAttributesMap["legalName"] && (
                                            <div className="flex items-center space-x-2">
                                                <span className="font-semibold text-sm">Business Legal Name:</span>
                                                <span className="text-sm">
                                                    {relatedAttributesMap['legalName'] || 'N/A'}
                                                </span>
                                            </div>
                                        )}

                                        {/* Address */}
                                        {relatedAttributesMap["address"] && (
                                            <div className="flex items-center space-x-2">
                                                <span className="font-semibold text-sm">Address:</span>
                                                <span className="text-sm">
                                                {relatedAttributesMap['address'] || 'N/A'}
                                                </span>
                                            </div>
                                        )}

                                        {/* UBI */}
                                        {relatedAttributesMap["ubi"]  && (
                                            <div className="flex items-center space-x-2">
                                                <span className="font-semibold text-sm">UBI:</span>
                                                <span className="text-sm">
                                                    {relatedAttributesMap["ubi"] || 'N/A' }
                                                </span>
                                            </div>
                                        )}

                                        {/* General Layer Item Info */}
                                        {/* Activity Date */}
                                        <div className="flex items-center space-x-2">
                                            <span className="font-semibold text-sm">Activity Date:</span>
                                            <span className="text-sm">{formattedDate}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
  
export default MapItemLandingPage;