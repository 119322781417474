import { useContext, useRef } from "react";
import { Chart } from "chart.js/auto";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TypesContext from "../../context/TypesContext";
import { IndustryType } from "../../services/types";
import { backgroundColorPlugin, noDataPlugin } from "../../utils/chartPlugins";

interface Industry {
    code: string;
    name: string;
    category: string;
    value: string;
}

interface InteractionSummaryData {
    total_interactions: string;
    industries: Industry[];
}

interface ActivitiesBySectorChartProps {
    interactionSummary: InteractionSummaryData | null;
}

function ActivitiesBySectorChart({ interactionSummary }: ActivitiesBySectorChartProps) {
    // Register the plugin to all charts:
    Chart.register(ChartDataLabels, noDataPlugin, backgroundColorPlugin);
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart<"bar"> | null>(null);

    const { typesData } = useContext(TypesContext);
    const industryTypes = typesData.industry_types;

    useDeepCompareEffect(() => {
        if (!interactionSummary) return;

        const { industries } = interactionSummary;

        // Function to get unique categories
        const getUniqueCategories = (data: IndustryType[]): string[] => {
            const categories = data.map(industry => industry.category);
            return Array.from(new Set(categories));
        };

        // Set unique categories based on industry_types
        const uniqueCategories = getUniqueCategories(industryTypes);

        // Initialize sectorTotals with unique categories
        const sectorTotals: { [key: string]: number } = {};
        uniqueCategories.forEach(category => {
            sectorTotals[category] = 0;
        });

        // Calculate totals for each category
        industries.forEach(industry => {
            const category = industry.category;
            if (sectorTotals.hasOwnProperty(category)) {
                sectorTotals[category] += parseInt(industry.value, 10);
            }
        });

        // Prepare data for Chart.js
        const labels = Object.keys(sectorTotals);
        const data = Object.values(sectorTotals);

        // Chart.js configuration
        const ctx = chartRef.current?.getContext("2d");
        if (ctx) {

            // Destroy existing chart instance if it exists
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            chartInstance.current = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "Activities by Sector",
                            data: data,
                            backgroundColor: '#4D4D4D',
                            borderRadius: 3,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            backgroundColor: "#FFFFFF",
                            titleColor: "#4D4D4D", 
                            bodyColor: "#4D4D4D",
                            borderColor: "#4D4D4D",
                            borderWidth: 1,
                            titleFont: {
                                family: 'Gotham'
                            },
                            bodyFont: {
                                family: 'Gotham'
                            }
                        },
                        datalabels: {
                            color: '#4D4D4D',
                            anchor: 'end',
                            align: 'end',
                            font: {
                                family: 'Gotham',
                                size: 10,
                                weight: 'normal'
                            },
                            offset: 5,
                            formatter: (value) => value !== 0 ? value : null
                        },
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            ticks: {
                                color: '#4D4D4D',
                                precision: 0,
                                font: {
                                    family: 'Gotham',
                                    size: 10,
                                    weight: 'normal'
                                },
                                padding: 10
                            },
                            grid: {
                                tickBorderDash: [2, 4]
                            },
                            border: {
                                display: false,
                                dash: [2,4]
                            }
                        },
                        y: {
                            grid: {
                                display: false,
                                drawTicks: true,
                            },
                            border: {
                                display: false
                            },
                            ticks: {
                                color: '#4D4D4D',
                                autoSkip: false,  
                                minRotation: 0,
                                maxRotation: 0,
                                font: {
                                    family: 'Gotham',
                                    size: 10,
                                    weight: 'normal'
                                },
                            },
                        },
                    },
                },
                plugins: [noDataPlugin, backgroundColorPlugin]
            });
        }
    }, [interactionSummary]);

    return ( 
        <div className="chart-container w-full h-84 min-w-lg max-w-lg border border-solid border-lightGray rounded-md appearance-none bg-at-white">
            <h2 className="text-white text-base font-bold py-2 px-3 bg-gotham-gray rounded-t-md">Interactions by Sector</h2>
            <div className="flex items-center border-t">
            </div>
            <canvas ref={chartRef} className="max-h-72 max-w-md mx-8 mt-12"/>
        </div>
     );
}

export default ActivitiesBySectorChart;