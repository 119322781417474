import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './App.css';
import './index.css';
import Targets from './views/Targets/Targets.tsx';
import Initiatives from './views/Initiatives/Initiatives.tsx';
import Landing from './views/Landing/Landing.tsx';
import EditTarget from './views/Targets/EditTarget.tsx';
import InitiativeDetail from './views/Initiatives/InitiativeDetail.tsx';
import Interactions from './views/Interactions/Interactions.tsx';
import UploadInteractions from './components/Interactions/UploadInteractions.tsx';
import ValidateDataFrame from './views/Initiatives/ValidateDataFrame.tsx';
import UploadSuccess from './views/Initiatives/UploadSuccess.tsx';
import EditInteractionUpload from './views/Initiatives/EditInteractionUpload.tsx';
import UploadHistory from './views/Initiatives/UploadHistory.tsx';
import AddTarget from './views/Targets/AddTarget.tsx';
import { AdminProvider } from './context/AdminContext.tsx';
import EditOutcome from './views/Outcomes/EditOutcome.tsx';
import AddOutcome from './views/Outcomes/AddOutcome.tsx';
import Summary from './views/Summary/Summary.tsx';
import { OutcomesDataProvider } from './context/OutcomeDataContext.tsx';
import { InitiativeDataProvider } from './context/InitiativeDataContext.tsx';
import { InteractionDataProvider } from './context/InteractionDataContext.tsx';
import { TargetsDataProvider } from './context/TargetDataContext.tsx';
import AddInitiative from './views/Initiatives/AddInitiative.tsx';
import EditInitiative from './views/Initiatives/EditInitiative.tsx';
import DeleteInitiative from './views/Initiatives/DeleteInitiative.tsx';
import AddInteraction from './views/Interactions/AddInteraction.tsx';
import EditInteraction from './views/Interactions/EditInteraction.tsx';
import MoveInteractions from './views/Interactions/MoveInteractions.tsx';
import BulkDeleteInteractions from './views/Interactions/BulkDeleteInteractions.tsx';
import { IframeProvider } from './context/IframeContext.tsx';
import IframedEditInteraction from './views/Interactions/IframedEditInteraction.tsx';
import { AdoDataProvider } from './context/AdoDataContext.tsx';
import { TypesProvider } from './context/TypesContext.tsx';
import { OrganizationProvider } from './context/OrganizationContext.tsx';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'; // Import Auth0Provider
import DeleteTarget from './views/Targets/DeleteTarget.tsx';
import DeleteInteraction from './views/Interactions/DeleteInteraction.tsx';
import DeleteOutcome from './views/Outcomes/DeleteOutcome.tsx';
import DraftSuccess from './views/Interactions/DraftSuccess.tsx';
import Surveys from './views/Surveys/Surveys.tsx';
import Projects from './views/Projects/Projects.tsx';
import DeleteProject from './views/Projects/DeleteProject.tsx';
import ProjectDetail from './views/Projects/ProjectDetail.tsx';
import { ProjectDataProvider } from './context/ProjectDataContext.tsx';
import IframedAddInteraction from './views/Interactions/IframedAddInteraction.tsx';
import MapItemLandingPage from './views/Interactions/MapItemLandingPage.tsx';

function AppContent() {
  const isIframe = window.self !== window.top;
  const [messageSent, setMessageSent] = useState(false);
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    // Redirect to login if not authenticated, but avoid redirection if already logging in or authenticated
    if (!isIframe && !isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isIframe, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {

        // If app is loaded within an iframe, send a "ready" message to the parent window
        if (!messageSent && window.parent !== window) {
          window.parent.postMessage({ message: "ready" }, "*");
          setMessageSent(true);
        }
    
        // // Iframe events
        // window.addEventListener("message", receiveMessage, false);
    
        // return () => {
        //   // Cleanup event listener when component unmounts
        //   window.removeEventListener("message", receiveMessage);
        // };
      }, [messageSent]);


  return (
    <Router>
      <AdoDataProvider>
        <OrganizationProvider>
            <AdminProvider>
              <IframeProvider>
                <TypesProvider>
                  <InitiativeDataProvider>
                    <ProjectDataProvider>
                      <TargetsDataProvider>
                        <InteractionDataProvider>
                          <OutcomesDataProvider>
                              <Routes>
                                <Route path="/" element={<Landing />} />

                                {/* Target Routes */}
                                <Route path="/commerce-targets" element={<Targets />} />
                                <Route path="/commerce-targets/addtarget" element={<AddTarget />} />
                                <Route path="/commerce-targets/edit/:id" element={<EditTarget />} />
                                <Route path="/commerce-targets/delete/:id" element={<DeleteTarget />} />

                                {/* Initiative Routes */}
                                <Route path="/commerce-initiatives" element={<Initiatives />} />
                                <Route path="/commerce-initiatives/addinitiative" element={<AddInitiative />} />
                                <Route path="/commerce-initiatives/edit/:id" element={<EditInitiative />} />
                                <Route path="/commerce-initiatives/delete/:id" element={<DeleteInitiative />} />
                                <Route path="/commerce-initiatives/detail/:id" element={<InitiativeDetail />} />

                                {/* Project Routes */}
                                <Route path="/commerce-projects" element={<Projects />} />
                                <Route path="/commerce-projects/addproject" element={<AddInitiative />} />
                                <Route path="/commerce-projects/edit/:id" element={<EditInitiative />} />
                                <Route path="/commerce-projects/delete/:id" element={<DeleteProject />} />
                                <Route path="/commerce-projects/detail/:id" element={<ProjectDetail />} />

                                {/* Outcome Routes */}
                                <Route path="/commerce-outcomes/addoutcome" element={<AddOutcome />} />
                                <Route path="/commerce-outcomes/edit/:id" element={<EditOutcome />} />
                                <Route path="/commerce-outcomes/delete/:id" element={<DeleteOutcome />} />

                                {/* Interaction Routes */}
                                <Route path="/commerce-interactions" element={<Interactions />} />
                                <Route path="/commerce-interactions/addinteraction" element={<AddInteraction />} />
                                <Route path="/commerce-interactions/edit/:id" element={<EditInteraction />} />
                                <Route path="/commerce-interactions/delete/:id" element={<DeleteInteraction />} />
                                <Route path="/commerce-interactions/move" element={<MoveInteractions />} />
                                <Route path="/commerce-interactions/bulkdelete" element={<BulkDeleteInteractions />} />

                                {/* Upload Interaction Routes */}
                                <Route path="/upload-interactions" element={<UploadInteractions />} />
                                <Route path="/upload-interactions/validate" element={<ValidateDataFrame />} />
                                <Route path="/upload-interactions/edit/:id" element={<EditInteractionUpload />} />
                                <Route path="/upload-interactions/draftsaved" element={<DraftSuccess/>} />
                                <Route path="/upload-interactions/success" element={<UploadSuccess />} />
                                <Route path="/upload-history" element={<UploadHistory />} />

                                {/* Summary Route */}
                                <Route path="/commerce-summary" element={<Summary />} />

                                {/* Surveys Route */}
                                <Route path="/commerce-surveys" element={<Surveys />} />

                                {/* Landing Page for Mapped Item Points */}
                                <Route path="/map-item-details" element={<MapItemLandingPage />} />
                                {/* Iframed Edit Interaction Page */}
                                <Route path="/edit-interaction/:id" element={<IframedEditInteraction />} />
                                {/* Iframed Add Interaction Page */}
                                <Route path="/add-interaction" element={<IframedAddInteraction />} />
                              </Routes>
                          </OutcomesDataProvider>
                        </InteractionDataProvider>
                      </TargetsDataProvider>
                    </ProjectDataProvider>
                  </InitiativeDataProvider>
                </TypesProvider>
              </IframeProvider>
            </AdminProvider>
        </OrganizationProvider>
      </AdoDataProvider>
    </Router>
  );
}

function App() {
  return (
    <Auth0Provider
      domain="dev-7h8ffv16mgngdqfp.us.auth0.com"
      clientId="v32C1JNCKXsd8cC9hOKRoeA9HlfA6h8J"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
      <AppContent />
    </Auth0Provider>
  );
}

export default App
